<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request.js';
import ProductList from './table/table.js';

formCreate.component('productList', ProductList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [], // 渲染表单

    };
  },
  async created() {
    const res = await this.getFormRule('cps_consumption_integral_form');
  },
  methods: {
    formComplete() {
      this.getDetail();
    },
    getDetail() {
      request
        .get('/cps-mall/v1/order/consumerOrderDetails/findOrderById', { id: this.formConfig.id })
        .then((res) => {
          if (res.success) {
            let goodsName = '';
            let number = 0;
            if (res.result.goodsList) {
              res.result.goodsList.forEach((item, index) => {
                if (index < res.result.goodsList.length - 1) {
                  goodsName += `${item.goodsName}；`;
                } else {
                  goodsName += `${item.goodsName}`;
                }
                number += item.number;
              });
            }
            const result = {
              ...this.formConfig,
              ...res.result.baseInfo,
              ...res.result.details,
              productList: res.result.goodsList.map((item) => ({
                ...item, price: item.price * item.number,
              })),
              goodsName,
              number,
              totalPrice: this.formConfig.totalPrice,
            };
            this.setValue({ ...result });
          }
        });
    },
  },
};
</script>
