  <template>
  <div class="custom-table">
    <vxe-table border show-overflow ref="xXTable" :data="productList" :seq-config="{startIndex: 0}">
      <vxe-column type="seq" title="序号" width="60"></vxe-column>
      <vxe-table-column field="pictureForList" title="积分商品列表图" class-name="formatter-image">
        <!-- <template #default="{ row }"> -->
        <template #default="{row}">
          <el-image style="width: 100px; height: 100px" :src="row.pictureForList" :preview-src-list="[row.pictureForList]">
          </el-image>
        </template>
      </vxe-table-column>
      <vxe-table-column field="goodsCode" title="积分商品编码"></vxe-table-column>
      <vxe-table-column field="goodsName" title="积分商品名称"></vxe-table-column>
      <vxe-table-column field="price" title="实付积分"></vxe-table-column>
      <vxe-table-column field="number" title="兑换数量"></vxe-table-column>
    </vxe-table>

  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        console.log(v);
        if (v) {
          this.productList = v;
        }
      },
    },
  },
  data() {
    return {
      productList: [],
    };
  },
  created() {},
};
</script>
<style lang="less" scoped>
.custom-table {
  padding: 20px;
}
/deep/ .formatter-image {
  .vxe-cell,
  .vxe-cell--html {
    height: 120px !important;
    max-height: 120px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
}
</style>
